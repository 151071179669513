// LandingPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { Heading } from './Heading';
import { ContactSection } from './ContactSection';
import FileUpload from './FileUpload';
import TelegramIntegration from './TelegramIntegration';
import Partnership from './Partnership';
import Support from './Support';
import Social from './Social';
import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';
import './LandingPage.css';

// Debounce utility to delay repeated scroll events
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

export const LandingPage = () => {
  // List of sections
  const sections = [
    { id: 0, component: null, key: 'heading' }, // We'll handle the Heading separately
    { id: 1, component: <FileUpload />, key: 'fileupload' },
    { id: 2, component: <TelegramIntegration />, key: 'telegram' },
    { id: 3, component: <Partnership />, key: 'partnership' },
    { id: 4, component: <Support />, key: 'support' },
    { id: 5, component: <Social />, key: 'social' },
    { id: 6, component: <ContactSection />, key: 'contact' },
  ];

  const [activeSection, setActiveSection] = useState(0);

  // Handle wheel events for desktop
  const handleWheel = useCallback(
    debounce((event) => {
      if (event.deltaY > 0) {
        // Scroll down
        setActiveSection((prevSection) =>
          Math.min(prevSection + 1, sections.length - 1)
        );
      } else {
        // Scroll up
        setActiveSection((prevSection) => Math.max(prevSection - 1, 0));
      }
    }, 50),
    [sections.length]
  );

  // Swipe handlers using react-swipeable
  const handlers = useSwipeable({
    onSwipedUp: () => {
      setActiveSection((prevSection) =>
        Math.min(prevSection + 1, sections.length - 1)
      );
    },
    onSwipedDown: () => {
      setActiveSection((prevSection) => Math.max(prevSection - 1, 0));
    },
    delta: 50, // Minimum distance (in pixels) before a swipe is detected
    preventDefaultTouchmoveEvent: true, // Prevent scrolling while swiping
    trackTouch: true, // Track touch input
  });

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => window.removeEventListener('wheel', handleWheel);
  }, [handleWheel]);

  // Define animation variants
  const variants = {
    initial: {
      opacity: 0,
      y: 0, // Remove vertical movement
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: 0, // Remove vertical movement
    },
  };

  return (
    <div className="landing-page" {...handlers}>
      <AnimatePresence mode="wait">
        {sections.map((section) =>
          activeSection === section.id ? (
            <motion.div
              key={section.key}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              className="container"
            >
              {section.id === 0 ? (
                <div className="alignment-wrapper">
                  <div className="header">
                    <div className="left-section">
                      <div className="logo-container">
                        <img
                          className="logo"
                          alt="Logo"
                          src={`${process.env.PUBLIC_URL}/FullLogo_Transparent-2 1.png`}
                        />
                        <div className="text-wrapper">Vallpoint</div>
                      </div>
                    </div>
                    <div className="right-section">
                      <a href="mailto:sales@vallpoint.com" className="get-in-touch">
                        GET IN TOUCH
                      </a>
                    </div>
                  </div>
                  {/* Include the Heading component inside the alignment-wrapper */}
                  <Heading className="heading-instance" />
                </div>
              ) : (
                section.component
              )}
            </motion.div>
          ) : null
        )}
      </AnimatePresence>
    </div>
  );
};
