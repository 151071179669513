// TelegramIntegration.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './TelegramIntegration.css';

const TelegramIntegration = () => {
  const [status, setStatus] = useState('linking'); // 'linking' or 'linked'

  useEffect(() => {
    const timer = setTimeout(() => {
      setStatus('linked'); // Switch to 'linked' after 3 seconds
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="integration-container">
      <div className="square-wrapper">
        <div className="white-box">
          {status === 'linking' ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/telegram_logo.png`}
                alt="Telegram Icon"
                className="telegram-icon"
              />
              <span className="status-text">Linking Telegram...</span>
              <div className="progress-bar">
                <div className="progress"></div>
              </div>
            </>
          ) : (
            <AnimatePresence>
              <motion.div
                className="fade-in-wrapper"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }} // Smooth 1-second fade-in
              >
                <img
                  src={`${process.env.PUBLIC_URL}/telegram_logo.png`}
                  alt="Telegram Icon"
                  className="telegram-icon"
                />
                <span className="status-text">Successfully linked.</span>
                <div className="checkmark-wrapper">
                  <div className="checkmark">➔</div>
                </div>
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>
      <div className="text-section">
        <h2>Telegram API Integration</h2>
        <div className="underline"></div>
        <p>
          Seamlessly link your Telegram accounts for real-time communication, with full access to
          message history and API capabilities directly within the dashboard.
        </p>
        <p>
          Automate routine messaging tasks with bot integrations and message scheduling. Enhance your
          channel’s visibility with SEO tools to monitor engagement rates and growth.
        </p>
      </div>
    </div>
  );
};

export default TelegramIntegration;
