// Social.js
import React from 'react';
import './Social.css';

const Social = () => {
  return (
    <div className="social-container">
      <div className="image-wrapper">
        <img
          src={`${process.env.PUBLIC_URL}/socialmedia.png`}
          alt="Social Media Connection"
          className="social-image"
        />
      </div>
      <div className="text-section">
        <h2>Social Media Connection</h2>
        <div className="underline"></div>
        <p>
          Connect your social media accounts effortlessly through a centralized dashboard with
          multi-platform API integration and real-time analytics tools.
        </p>
        <p>
          Benefit from exclusive access to Vallpoint’s partnership with an external media company
          with 10k+ MRR, providing you with targeted advertising and content sponsorships.
        </p>
      </div>
    </div>
  );
};

export default Social;
