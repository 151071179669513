import React, { useState, useRef } from 'react';
import './FileUpload.css';

const FileUpload = () => {
  const [fileStatus, setFileStatus] = useState('idle'); // idle, hover, analyzing, complete
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const handleDragOver = (event) => {
    event.preventDefault();
    setFileStatus('hover'); // File dragged over the drop zone
  };

  const handleDragLeave = () => {
    setFileStatus('idle'); // Reset to idle when leaving the drop zone
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFileStatus('analyzing'); // Start analyzing

    // Simulate the analyzing process with a timeout
    setTimeout(() => setFileStatus('complete'), 3000);
  };

  const handleClick = () => {
    // Trigger the file input when the user taps the drop zone on mobile
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // Handle file selection from the input field
    if (event.target.files.length > 0) {
      setFileStatus('analyzing');
      setTimeout(() => setFileStatus('complete'), 3000);
    }
  };

  return (
    <div className="upload-container">
      <div className="drop-zone-wrapper">
        <div
          className={`drop-zone ${fileStatus}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClick} // Allow clicking on mobile
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {fileStatus === 'complete' ? (
            <span>Analyzing completed!</span>
          ) : fileStatus === 'analyzing' ? (
            <div className="progress-container">
              <span>Analyzing file...</span>
              <div className="progress-bar">
                <div className="progress"></div>
              </div>
            </div>
          ) : (
            <span>
              {fileStatus === 'hover' ? 'Yes, drop it here!' : 'Drop or select your files...'}
            </span>
          )}
        </div>
      </div>
      <div className="text-section">
        <h2>Compliance Monitoring</h2>
        <div className="underline"></div>
        <p>
          Intuitive document upload system with AI-powered scanning ensures your
          documents are automatically checked for regulatory non-compliance.
        </p>
        <p>
          Stay on top of deadlines with automatic alerts and calendar sync. Easy
          document tracking with version control, audit logs, and full visibility on edits and approvals.
        </p>
      </div>
    </div>
  );
};

export default FileUpload;
