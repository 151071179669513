// Heading.js

import React, { useState, useEffect } from "react";
import "./Heading.css";

export const Heading = ({ className }) => {
  const variants = ["start-ups", "fortune 500s", "crypto pioneers"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textHeight, setTextHeight] = useState(83); // default value

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        return (prevIndex + 1) % variants.length;
      });
    }, 1800);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateTextHeight = () => {
      const width = window.innerWidth;
      let newTextHeight = 83; // default for desktop
      if (width <= 480) {
        newTextHeight = 40;
      } else if (width <= 768) {
        newTextHeight = 55;
      } else if (width <= 1024) {
        newTextHeight = 70;
      }
      setTextHeight(newTextHeight);
    };

    updateTextHeight();

    window.addEventListener("resize", updateTextHeight);
    return () => window.removeEventListener("resize", updateTextHeight);
  }, []);

  return (
    <div className={`heading ${className}`}>
      <div className="line-wrapper first-line">
        <span className="line-text">We help&nbsp;</span>
        <div className="scrolling-text-container">
          <div className="scrolling-text">
            <div
              className="scrolling-text-inner"
              style={{
                transform: `translateY(-${currentIndex * textHeight}px)`,
              }}
            >
              {variants.map((text, index) => (
                <div key={index} className="scrolling-text-item">
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <img
        className="line-svg"
        alt="Line 1"
        src={`${process.env.PUBLIC_URL}/lines/line-1.svg`}
      />

      <div className="line-wrapper">
        <span className="line-text">empower coins, build credibility,</span>
      </div>
      <img
        className="line-svg"
        alt="Line 2"
        src={`${process.env.PUBLIC_URL}/lines/line-2.svg`}
      />

      <div className="line-wrapper">
        <span className="line-text">and thrive in the TON network.</span>
      </div>
      <img
        className="line-svg"
        alt="Line 3"
        src={`${process.env.PUBLIC_URL}/lines/line-3.svg`}
      />
    </div>
  );
};
