// Partnership.js
import React from 'react';
import './Partnership.css';

const Partnership = () => {
  return (
    <div className="partnership-container">
      <div className="image-wrapper">
        <img
          src={`${process.env.PUBLIC_URL}/partnership.png`}
          alt="Partnership"
          className="partnership-image"
        />
      </div>
      <div className="text-section">
        <h2>Partnership Building</h2>
        <div className="underline"></div>
        <p>
          Access a curated list of partnership opportunities in the cryptocurrency and Web3
          industries. Through exclusive introductions, connect with key contacts and decision
          makers via our network.
        </p>
        <p>
          Track interactions with potential partners using a built-in CRM-style tool, helping
          manage and nurture your relationships.
        </p>
      </div>
    </div>
  );
};

export default Partnership;
