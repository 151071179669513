// Support.js
import React from 'react';
import './Support.css';

const Support = () => {
  return (
    <div className="support-container">
      <div className="image-wrapper">
        <img
          src={`${process.env.PUBLIC_URL}/submission.png`}
          alt="Support"
          className="support-image"
        />
      </div>
      <div className="text-section">
        <h2>Cryptocurrency Support</h2>
        <div className="underline"></div>
        <p>
          Submit your tokens to multiple exchanges through a centralized portal with pre-integrated
          application templates and step-by-step guided submission workflow.
        </p>
        <p>
          Track submission statuses across all exchanges in real time and leverage a comprehensive
          exchange directory and legal compliance tools for smooth exchange listings.
        </p>
      </div>
    </div>
  );
};

export default Support;
