// ContactSection.js
import React from 'react';
import './ContactSection.css';

export const ContactSection = () => {
  return (
    <div className="contact-section">
      <img
        className="contact-logo"
        src={`${process.env.PUBLIC_URL}/FullLogo_Transparent-2 1.png`}
        alt="Vallpoint Logo"
      />
      <p className="contact-text">
        We are currently in beta development and slowly rolling out software to
        specific users. For inquiries,
      </p>
      <a href="mailto:sales@vallpoint.com" className="contact-button">
        contact sales &rarr;
      </a>
    </div>
  );
};
